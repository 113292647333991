import React from 'react';

function Footer() {
	return (
		<table
			style={{
				width: '100%',
				backgroundColor: 'white',
				borderRadius: '0.5rem',
				marginTop: '1.25rem',
				border: '1px solid #e7f1ff',
				paddingTop: '1.75rem',
				paddingBottom: '1.75rem',
				paddingLeft: '1rem',
				paddingRight: '1rem',
			}}
		>
			<tr>
				<td
					style={{
						verticalAlign: 'top',
					}}
				>
					<div
						className="fs-125"
						style={{
							color: 'black',
							outline: 'none',
							fontWeight: 500,
						}}
					>
						Follow{' '}
						<span style={{ color: '#2379e9', fontWeight: 600 }}>
							Us.
						</span>
					</div>
					<table
						style={{
							marginTop: '1.25rem',
						}}
					>
						<tr>
							<td>
								<a href="https://www.facebook.com/people/InvestorBuzzcom/100083069332230/">
									<img
										style={{
											width: '1.5rem',
											marginRight: '1.5rem',
										}}
										src="https://i.ibb.co/zPMCF4W/image.png"
									/>
								</a>
							</td>
							<td>
								<a href="https://twitter.com/investor_buzz">
									<img
										style={{
											width: '1.5rem',
											marginRight: '1.5rem',
										}}
										src="https://i.ibb.co/7gfvhJ4/image.png"
									/>
								</a>
							</td>
						</tr>
					</table>
				</td>
				<td>
					<div
						className="fs-125"
						style={{
							paddingLeft: '10%',

							color: 'black',
							fontWeight: 500,
						}}
					>
						Contact{' '}
						<span
							style={{
								color: '#2379e9',
								fontWeight: 600,
							}}
						>
							Us.
						</span>
					</div>
					<div
						className="fs-0875"
						style={{
							paddingLeft: '10%',
							color: '#747474',
							marginTop: '1.25rem',
						}}
					>
						141 W. Jackson Blvd.
					</div>
					<div
						className="fs-0875"
						style={{
							paddingLeft: '10%',
							color: '#747474',
							marginTop: '0.5rem',
						}}
					>
						Chicago, IL 60604
					</div>
					<div
						className="fs-125"
						style={{
							paddingLeft: '10%',
							color: 'black',
							marginTop: '1.5rem',
							textDecoration: 'none',
						}}
					>
						<span
							style={{
								color: '#2379e9',
								fontWeight: 600,
							}}
						>
							hello@
						</span>
						<a
							href="https://investorbuzz.com"
							className="fs-125"
							style={{
								color: 'black',
								marginTop: '1.5rem',
								textDecoration: 'none',
							}}
						>
							investorbuzz.com
						</a>
					</div>
				</td>
			</tr>
		</table>
	);
}

export default Footer;
