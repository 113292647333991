import React from 'react';

import { Routes, Route } from 'react-router-dom';

import Editor from 'src/pages/Editor';
import Chart from 'src/pages/Chart';

function App() {
	return (
		<Routes>
			<Route path="/*" element={<Editor />} />
			<Route path="/chart/:symbol" element={<Chart />} />
		</Routes>
	);
}

export default App;
