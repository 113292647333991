import React from 'react';

function Loading() {
	return (
		<div
			className="render-ignore"
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<img
				src="https://i.ibb.co/x3CH7GN/gi.gif"
				alt=""
				style={{
					width: '8rem',
					marginRight: '3.5rem',
				}}
			/>
		</div>
	);
}

export default Loading;
