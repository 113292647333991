import React, { useState, useEffect } from 'react';

import { getYahooSymbol } from '../api/yahoo';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	TimeSeriesScale,
	BarElement,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import ms from 'ms';

import { useParams } from 'react-router-dom';
import Loading from 'src/components/Loading';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	TimeSeriesScale,
	BarElement,
);

const months = [
	'Jan.',
	'Feb.',
	'Mar.',
	'Apr.',
	'May.',
	'June.',
	'July.',
	'Aug.',
	'Sept.',
	'Oct.',
	'Nov.',
	'Dec.',
];

const chartOptions = {
	lineTension: 0.2,
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: false,
		},
		filler: {
			propagate: false,
		},
	},
	hover: { mode: null },
	spanGaps: true,
	scales: {
		y: {
			display: false,
			grid: {
				display: false,
				drawBorder: false,
			},
		},
		x: {
			display: false,
			grid: {
				drawBorder: false,
				display: false,
			},
			ticks: {
				callback: (value: any, index: any, ticks: any) => {
					const currentDay = new Date(
						new Date('January 1, 2021 00:00:01').getTime() +
							ms(`${value}d`),
					);
					if (currentDay.getDate() === 1)
						return months[currentDay.getMonth()];
				},
				color: '#979699',
				font: {
					size: 37,
					family: 'Poppins',
				},
				padding: 20,
			},
		},
	},
	elements: {
		point: {
			radius: 0,
		},
	},
};

const ChartComponent = () => {
	const { symbol } = useParams();

	const [data, setData] = useState<any>({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const load: any = async () => {
			setLoading(true);

			const local: any = await getYahooSymbol(symbol);

			local.history = local.history.reverse();

			console.log(
				[...local.history]
					.filter((date: any, index: any) =>
						date.date.includes('2023'),
					)
					.map((date: any) => date.close),
			);

			setData(local);
			setLoading(false);

			console.log(local);
		};

		load();
	}, []);

	if (loading) {
		return (
			<div className="w-screen h-screen flex justify-center items-center">
				<Loading />
			</div>
		);
	}

	return (
		<div
			style={{
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				justifyContent: 'center',
				backgroundColor: 'white',
			}}
		>
			<div className="font-semibold" style={{ width: '100%' }}>
				<div
					style={{
						fontSize: '6rem',
						color: '#6a747f',
						fontWeight: 500,
						fontFamily: 'Poppins',
					}}
				>
					{data?.name}
				</div>
				<div
					style={{
						fontSize: '9rem',
						color: 'black',
						fontWeight: 600,
						fontFamily: 'Poppins',
						marginTop: '1.5rem',
					}}
				>
					<span
						style={{
							fontSize: '7rem',
							color: '#2b343f',
							marginRight: '1.25rem',
							fontWeight: 500,
						}}
					>
						USD
					</span>
					{data?.close}
				</div>
				<h4
					style={{
						fontSize: '6rem',
						marginTop: '1.25rem',
						color: !data
							? '#2379e9'
							: data.changePercent > 0
							? '#0CBD8F'
							: '#cf0e4b',
						fontWeight: 600,
					}}
				>
					{!data ? '-' : data.changePercent.toFixed(2)}%{' '}
					<span
						style={{
							fontSize: '6rem',
						}}
					>
						({!data ? '-' : data.change < 0 ? '-' : '+'}$
						{!data ? '-' : Math.abs(data.change)})
					</span>
				</h4>
			</div>
			<div
				style={{
					width: '100%',
					marginTop: '2rem',
				}}
			>
				<Line
					height="20%"
					width="100%"
					data={{
						labels: [...data.history]
							.filter((date: any, index: any) =>
								date.date.includes('2022'),
							)
							.map((date: any) => date.date),
						datasets: [
							{
								data: [...data.history]
									.filter((date: any, index: any) =>
										date.date.includes('2023'),
									)
									.map((date: any) => date.close),
								borderWidth: 16,
								borderColor: '#2379E8',
							},
							{
								data: [...data.history]
									.filter((date: any, index: any) =>
										date.date.includes('2023'),
									)
									.map((date: any, index, values) =>
										index === values.length - 1
											? date.close
											: null,
									),
								borderWidth: 0,
								pointRadius: 10,
								pointBackgroundColor: '#2379E8',
							},

							{
								data: [...data.history]
									.filter((date: any, index: any) =>
										date.date.includes('2022'),
									)
									.map((date: any) => date.close),
								borderWidth: 16,
								borderColor: '#8eb9f3',
							},
							{
								data: [...data.history]
									.filter((date: any, index: any) =>
										date.date.includes('2022'),
									)
									.map((date: any, index, values) =>
										index === values.length - 1
											? date.close
											: null,
									),
								borderWidth: 0,
								pointRadius: 10,
								pointBackgroundColor: '#8eb9f3',
							},
						],
					}}
					options={chartOptions as any}
				/>
			</div>
		</div>
	);
};

export default ChartComponent;
