import React from 'react';

import type { ReactNode } from 'react';

function Email({ children }: { children: ReactNode }) {
	return (
		<div
			style={{
				width: '100%',
				maxWidth: '670px',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingBottom: '150px',
			}}
			className="email-container"
		>
			{children}
		</div>
	);
}

export default Email;
