import ms from 'ms';

export function getCurrentYearStart() {
	const today = new Date();

	return new Date(today.getFullYear(), 0, 1);
}

export function getCurrentDateLong() {
	const today = new Date();

	return {
		year: today.getFullYear(),
		month: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		][today.getMonth()],
		day: today.getDate(),
	};
}

export function getTimeIntervalFromPresent(interval: string | number) {
	if (typeof interval === 'number') {
		return {
			start: Date.now() - interval,
			end: Date.now(),
		};
	}

	return {
		start: Date.now() - ms(interval),
		end: Date.now(),
	};
}

export function getTimeIntervalFromYearStart(interval: string) {
	const start = getCurrentYearStart().getTime();

	return {
		start: start - ms(interval),
		end: start,
	};
}
