import { get, getNoTimeout } from '.';

export async function getYahooSymbols() {
	const symbols: any = (await get(`/yahoo`)).data;

	return symbols;
}

export async function getYahooSymbol(symbol?: string) {
	const symbols: any = (await getNoTimeout(`/yahoo/${symbol}`)).data;

	return symbols;
}
