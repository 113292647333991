import Axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const initializers = {
	baseURL: 'https://investorbuzzapi.achievevalue.tech',
	timeout: 30000,
};

const axios = Axios.create(initializers);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
	if (process.env.NODE_ENV === 'development') {
		const method = config.method;
		const headers = config.headers?.common || {};

		console.log(
			`Sending ${method?.toUpperCase()} to ${config.url} (${
				config.baseURL +
				(config?.url || '') +
				'?' +
				new URLSearchParams(config.params).toString()
			}) \n` +
				`Headers: ${Object.keys(headers).reduce(
					(acc: string, val: string) =>
						(acc += `    ${val}: ${
							headers[val as keyof typeof headers]
						}\n`),
					'\n',
				)}`,
		);
	}
	return config;
});

axios.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: AxiosError) => {
		console.log('err: ', error);
		if (
			error.response &&
			error.response.data &&
			process.env.NODE_ENV === 'development'
		) {
			const method = error.response.config.method || 'METHOD UNDEFINED';

			console.log(
				`Got error while sending ${method.toUpperCase()} to ${
					error.response.config.url
				} (${error.response.config.baseURL})\n` +
					`Error: ${error.message}`,
			);
		}
		return Promise.reject(error.message);
	},
);

export function get(url: string, params?: { [key: string]: unknown }) {
	return axios.get(url, { params });
}

export function getNoTimeout(url: string, params?: { [key: string]: unknown }) {
	return axios.get(url, { params, timeout: 0 });
}

export function post(url: string, data: unknown) {
	return axios.post(url, data);
}

export function put(url: string, data: unknown) {
	return axios.put(url, data);
}

export function patch(url: string, data: unknown) {
	return axios.patch(url, data);
}

export function del(url: string, params?: { [key: string]: unknown }) {
	return axios.delete(url, { params });
}
