import React from 'react';

import { getYahooSymbol } from 'src/api/yahoo';

function Trending({ data, setData }: { data: any; setData: any }) {
	const [sections, setSections] = React.useState<any>({
		stock: '^GSPC',
		crypto: 'BTC-USD',
		forex: 'EURUSD=X',
		futures: 'ZW=F',
		bond: '^USTTEN',
		metals: 'GC=F',
	});

	const names = {
		stock: 'Stocks',
		crypto: 'Crypto',
		forex: 'Forex',
		futures: 'Futures',
		bond: 'Bonds',
		metals: 'Metals',
	} as any;

	const updateSection = async (section: string, value: string, e: any) => {
		e.target.disabled = true;

		const requestYahoo = await getYahooSymbol(value);

		console.log(requestYahoo);

		if (requestYahoo.message) {
			setSections({ ...sections, [section]: value });
			e.target.disabled = false;
			return;
		}

		setSections({ ...sections, [section]: value });

		setData({
			...data,
			yahoo: {
				...data.yahoo,
				[requestYahoo.symbol]: requestYahoo,
			},
		});

		e.target.disabled = false;

		return;
	};

	console.log(data);

	return (
		<div
			style={{
				width: 'full',
				backgroundColor: 'white',
				borderRadius: '0.5rem',
				marginTop: '1.25rem',
				border: '1px solid #e7f1ff',
				paddingTop: '1.75rem',
			}}
		>
			<div
				className="fs-1875"
				style={{
					color: 'black',
					fontWeight: 700,
					marginTop: '0.75rem',
					paddingLeft: '1rem',
					paddingRight: '1rem',
				}}
			>
				Trending
			</div>

			{Object.keys({ ...sections }).map((section: string) => {
				return (
					<>
						<table
							style={{
								marginTop: '1.75rem',
								paddingRight: '1rem',
								paddingLeft: '1rem',
							}}
						>
							<tr>
								<td
									className="fs-125"
									style={{
										color: '#2379e8',
										fontWeight: 600,
										marginRight: '2.5rem',
									}}
								>
									{names[section]}
								</td>
								<td>
									<input
										className="fs-0875 render-ignore"
										type="text"
										style={{
											backgroundColor: 'transparent',
											border: 'none',
											outline: 'none',
											color: 'rgba(35, 121, 232, 1)',
											fontWeight: 600,
											verticalAlign: 'middle',
											marginLeft: '1rem',
											transitionDuration: '300ms',
										}}
										defaultValue={sections[section]}
										onBlur={async e => {
											await updateSection(
												section,
												e.target.value,
												e,
											);
										}}
									/>
								</td>
							</tr>
						</table>

						<table
							className="trending-quote-table"
							style={{
								tableLayout: 'fixed',
								borderSpacing: '0',
								marginTop: '0.25rem',
								width: '90%',
								paddingLeft: '1rem',
							}}
						>
							<tr
								style={{
									backgroundColor: '#e7f1ff',
									marginTop: '1.5rem',
								}}
							>
								<td
									contentEditable={true}
									className="fs-1075"
									style={{
										color: '#2379e8',
										fontWeight: 600,
										paddingLeft: '1.5rem',
										paddingTop: '0.5rem',
										paddingBottom: '0.5rem',
										borderTopLeftRadius: '10px',
										borderBottomLeftRadius: '10px',
										outline: 'none',
									}}
								>
									{data?.yahoo?.symbols &&
									data?.yahoo?.symbols[sections[section]]
										? data.yahoo.symbols[sections[section]]
												.name.length > 20
											? data.yahoo.symbols[
													sections[section]
											  ].code.replace('^', '')
											: data.yahoo.symbols[
													sections[section]
											  ].name
										: '-'}
								</td>
								<td
									align="center"
									className="fs-1075"
									style={{
										color:
											data?.yahoo?.symbols &&
											data?.yahoo?.symbols[
												sections[section]
											]
												? data.yahoo.symbols[
														sections[section]
												  ].changePercent > 0
													? '#0CBD8F'
													: '#cf0e4b'
												: '#2379e9',
										fontWeight: 600,
										textAlign: 'center',
									}}
								>
									{data?.yahoo?.symbols &&
									data?.yahoo?.symbols[sections[section]] &&
									data.yahoo.symbols[sections[section]]
										.changePercent
										? data.yahoo.symbols[
												sections[section]
										  ].changePercent.toFixed(2) + '%'
										: '-'}
									&nbsp;
									<span className="fs-075">
										(
										{data?.yahoo?.symbols &&
										data?.yahoo?.symbols[sections[section]]
											? data.yahoo.symbols[
													sections[section]
											  ].change < 0
												? '-'
												: '+'
											: '-'}
										$
										{data?.yahoo?.symbols &&
										data?.yahoo?.symbols[sections[section]]
											? Math.abs(
													data.yahoo.symbols[
														sections[section]
													].change,
											  )
											: '-'}
										)
									</span>
								</td>
								<td
									className="fs-1075"
									style={{
										color: '#2379e8',
										fontWeight: 600,
										paddingRight: '1.5rem',
										borderTopRightRadius: '10px',
										borderBottomRightRadius: '10px',
										verticalAlign: 'middle',
									}}
									align="right"
								>
									$
									{data?.yahoo?.symbols &&
									data?.yahoo?.symbols[sections[section]]
										? data.yahoo.symbols[sections[section]]
												.close
										: '-'}
								</td>
							</tr>
						</table>

						{data.news
							? data?.news[section]?.map(
									(article: any, index: number) => (
										<table
											className="trending-news"
											key={index}
											style={{
												marginTop: '1rem',
												paddingLeft: '1rem',
											}}
										>
											<tr>
												<td>
													<img
														style={{
															width: '1.5rem',
															marginRight:
																'0.75rem',
															verticalAlign:
																'middle',
														}}
														src="https://i.ibb.co/FhqJ2WX/image.png"
														alt=""
													/>
												</td>
												<td
													style={{
														verticalAlign: 'middle',
													}}
												>
													<a
														className="fs-1025 article-title"
														href={article.url}
														style={{
															color: 'black',
															verticalAlign:
																'middle',
															textDecoration:
																'none',
															whiteSpace:
																'nowrap',
															overflow: 'hidden',
															textOverflow:
																'ellipsis',
															display:
																'inline-block',
														}}
													>
														<span style={{}}>
															<span
																style={{
																	fontWeight: 600,
																}}
															>
																{article?.title
																	? article.title
																			.split(
																				' ',
																			)
																			.slice(
																				0,
																				3,
																			)
																			.join(
																				' ',
																			)
																	: 'Unknown Title'}
															</span>
															&nbsp;
															{article?.title
																? article.title
																		.split(
																			' ',
																		)
																		.slice(
																			3,
																		)
																		.join(
																			' ',
																		)
																: ''}
														</span>
													</a>
												</td>
											</tr>
										</table>
									),
							  )
							: null}
					</>
				);
			})}

			<table
				style={{
					width: '100%',
					backgroundColor: '#e7f1ff',
					borderBottomLeftRadius: '0.5rem',
					borderBottomRightRadius: '0.5rem',
					marginTop: '2.25rem',
					paddingTop: '0.2rem',
					paddingBottom: '0.2rem',
				}}
			>
				<tr>
					<td></td>

					<td
						align="right"
						style={{
							width: '1.5rem',
						}}
					>
						<a
							href={
								'https://www.facebook.com/sharer/sharer.php?u=' +
								'https://investorbuzz.com/latest-newsletter/'
							}
							style={{
								width: '100%',
								marginRight: '1.35rem',
							}}
						>
							<img
								style={{
									width: '1.5rem',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/Cv491Nr/image.png"
							/>
						</a>
					</td>

					<td
						align="right"
						style={{
							width: '1.5rem',
						}}
					>
						<a
							href={`http://twitter.com/share?text=Investorbuzz Newsletter&url=https://investorbuzz.com/latest-newsletter/`}
							style={{
								width: '100%',
								marginRight: '1.35rem',
							}}
						>
							<img
								style={{
									width: '1.5rem',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/ynLcfgG/image.png"
							/>
						</a>
					</td>

					<td
						align="right"
						style={{
							width: '1.5rem',
							paddingRight: '1rem',
						}}
					>
						<a
							href={`mailto:?subject=Investorbuzz Newsletter&body=https://investorbuzz.com/latest-newsletter/`}
							style={{
								width: '100%',
							}}
						>
							<img
								style={{
									width: '100%',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/5xsv1W7/image.png"
							/>
						</a>
					</td>
				</tr>
			</table>
		</div>
	);
}

export default Trending;
