import React, { useEffect } from 'react';

import Email from 'src/containers/Email';

import InTodaysBuzz from 'src/components/Sections/InTodaysBuzz';
import Article from 'src/components/Sections/Article';
import Trending from 'src/components/Sections/Trending';
import Footer from 'src/components/Sections/Footer';
import Error from 'src/components/Error';

import { getBarchartGainers } from '../api/barchart';
import { getInvestorbuzzArticle } from '../api/investorbuzz';
import { getYahooSymbols } from '../api/yahoo';
import { getNews } from '../api/news';

function Editor() {
	const [data, setData] = React.useState({});
	const [loading, setLoading] = React.useState({
		barchart: true,
		investorbuzz: true,
		yahoo: true,
		news: true,
	});
	const [error, setError] = React.useState(false);

	useEffect(() => {
		const load: any = async () => {
			try {
				await getBarchartGainers().then(res => {
					setData(prev => ({ ...prev, barchart: res }));
					setLoading(prev => ({ ...prev, barchart: false }));
					setError(false);

					return res;
				});

				await getInvestorbuzzArticle().then(res => {
					setLoading(prev => ({ ...prev, investorbuzz: false }));
					setData(prev => ({ ...prev, investorbuzz: res }));
					setError(false);

					return;
				});

				await getYahooSymbols().then(res => {
					setData(prev => ({ ...prev, yahoo: res }));
					setLoading(prev => ({ ...prev, yahoo: false }));
					setError(false);

					return;
				});

				setLoading(prev => ({ ...prev, news: true }));
				await getNews().then(res => {
					setData(prev => ({ ...prev, news: res }));
					setLoading(prev => ({ ...prev, news: false }));
					setError(false);

					return;
				});
			} catch (_) {
				setError(true);
				setLoading({
					barchart: false,
					investorbuzz: false,
					yahoo: false,
					news: false,
				});

				console.log(_);

				return;
			}

			setTimeout(() => {
				load();
			}, 1000);
		};

		load();
	}, []);

	return (
		<Email>
			<div
				className="notifications"
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div>{error && <Error />}</div>

				<div className="render-ignore">
					<h4
						className="fs-0875"
						style={{
							color: error
								? 'rgb(207, 14, 75, 0.7)'
								: 'rgba(0,0,0,0.7)',
							cursor: error ? 'not-allowed' : 'pointer',
							fontWeight: 500,
						}}
						onClick={(e: any) => {
							if (error) return;

							e.target.style.color = '#18bf94';
							e.target.innerHTML = 'Copied!';

							Array.from(
								document.querySelectorAll('.render-ignore'),
							).map((el: any) => {
								el.style.display = 'none';
							});

							navigator.clipboard.writeText(
								'<!DOCTYPE html>' +
									document.documentElement.outerHTML,
							);

							Array.from(
								document.querySelectorAll('.render-ignore'),
							).map((el: any) => {
								el.style.display = 'block';
							});

							setTimeout(() => {
								e.target.style.color = 'black';
								e.target.innerHTML = 'Save';
							}, 3500);
						}}
					>
						{error ? 'Saving is disabled' : 'Save'}
					</h4>
				</div>
			</div>

			<InTodaysBuzz data={data} loading={loading} />

			<Article data={data} />

			<Trending data={data} setData={setData} />

			<Footer />
		</Email>
	);
}

export default Editor;
