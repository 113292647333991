import React from 'react';

import { getCurrentDateLong } from 'src/utils/time';

import Loading from '../Loading';

function InTodatsByzz({ data, loading }: { data: any; loading: any }) {
	return (
		<div
			style={{
				width: 'full',
				backgroundColor: 'white',
				borderRadius: '0.5rem',
				paddingTop: '1.25rem',
				paddingBottom: '1.25rem',
			}}
		>
			<h4
				className="fs-0775"
				style={{
					color: '#9799a1',
					fontWeight: 400,
				}}
			>
				{getCurrentDateLong().month} {getCurrentDateLong().day},{' '}
				{getCurrentDateLong().year}
			</h4>

			<div style={{ width: '100%', textAlign: 'center' }}>
				<img
					src="https://i.ibb.co/fM0WtBx/image.png"
					style={{
						width: '40%',
					}}
				/>
			</div>
			<div
				className="fs-125"
				contentEditable={true}
				style={{
					outline: 'none',
					color: 'black',
					marginTop: '2.5rem',
				}}
			>
				Feel free to edit this section and
				<b>&nbsp;make parts of it stand out with CTRL + B</b> (I&apos;m
				sorry, Mac users 😄).
			</div>
			<div
				className="fs-125"
				style={{
					color: '#2379e8',
					fontWeight: 600,
					marginTop: '1.75rem',
				}}
			>
				In today&apos;s buzz:
			</div>
			<div style={{ paddingLeft: '1.5rem' }}>
				<div
					className="fs-1125"
					style={{
						color: 'black',
						marginTop: '0.75rem',
					}}
				>
					<span style={{ fontWeight: 600 }}>• </span>
					<span
						contentEditable={true}
						style={{ outline: '2px solid transparent' }}
					>
						<b>Roger Babson: </b>
						If things go wrong, don’t go with them.
					</span>
				</div>
				<div
					className="fs-1125"
					style={{
						color: 'black',
						marginTop: '0.75rem',
					}}
				>
					<span style={{ fontWeight: 600 }}>• </span>
					<span
						contentEditable={true}
						style={{ outline: '2px solid transparent' }}
					>
						<b>Oscar Wilde: </b>
						Be yourself; everyone else is already taken.
					</span>
				</div>
				<div
					className="fs-1125"
					style={{
						color: 'black',
						marginTop: '0.75rem',
					}}
				>
					<span style={{ fontWeight: 600 }}>• </span>
					<span
						contentEditable={true}
						style={{ outline: '2px solid transparent' }}
					>
						<b>William James: </b>
						Act as if what you do makes a difference. It does.
					</span>
				</div>
			</div>

			<div
				className="fs-125"
				style={{
					color: '#2379e8',
					fontWeight: 600,
					marginTop: '1.75rem',
				}}
			>
				5 Day Gainers:
			</div>
			<table
				style={{
					borderCollapse: 'separate',
					borderSpacing: '0 0.5rem',
					width: '100%',
					tableLayout: 'fixed',
				}}
			>
				<tr style={{ width: '100%' }}>
					<td
						className="fs-0875"
						style={{
							color: '#747474',
						}}
						align="left"
					>
						&nbsp;&nbsp;Symbol
					</td>
					<td
						className="fs-0875"
						style={{
							color: '#747474',
						}}
						align="center"
					>
						Change
					</td>
					<td
						className="fs-0875"
						style={{
							color: '#747474',
						}}
						align="center"
					>
						Last Price
					</td>
					<td
						className="fs-0875 mobile-hide-table-cell"
						style={{
							color: '#747474',
							display: 'none',
						}}
						align="center"
					>
						5D High
					</td>
					<td
						className="fs-0875 mobile-hide-table-cell"
						style={{
							color: '#747474',
							display: 'none',
						}}
						align="center"
					>
						5D Low
					</td>
					<td
						className="fs-0875"
						style={{
							color: '#747474',
						}}
						align="right"
					>
						Market Cap&nbsp;&nbsp;
					</td>
				</tr>
				{(!loading.barchart &&
					data?.barchart?.symbols?.map(
						(symbol: any, index: number) => (
							<tr key={index} className="">
								<td
									className="fs-1075"
									style={{
										color: 'black',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
									}}
									align="left"
								>
									&nbsp;&nbsp;{symbol.symbol}
								</td>
								<td
									className="fs-1075"
									style={{
										color: '#0CBD8F',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
									}}
									align="center"
								>
									{symbol.change}
								</td>
								<td
									className="fs-1075"
									style={{
										color: 'black',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
									}}
									align="center"
								>
									${symbol.last.toFixed(2)}
								</td>
								<td
									className="fs-1075 mobile-hide-table-cell"
									style={{
										color: 'black',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
										display: 'none',
									}}
									align="center"
								>
									${symbol.high.toFixed(2)}
								</td>
								<td
									className="fs-1075 mobile-hide-table-cell"
									style={{
										color: 'black',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
										display: 'none',
									}}
									align="center"
								>
									${symbol.low.toFixed(2)}
								</td>
								<td
									className="fs-1075"
									style={{
										color: 'black',
										fontWeight: 600,
										paddingTop: '0.25rem',
										paddingBottom: '0.25rem',
										backgroundColor:
											index % 2 == 1
												? '#e7f1ff'
												: 'white',
									}}
									align="right"
								>
									$
									{(
										symbol.marketCapitalization / 1000000
									).toFixed(3)}
									M&nbsp;&nbsp;
								</td>
							</tr>
						),
					)) ||
					null}
			</table>

			{!loading.barchart && !data?.barchart?.symbols && (
				<div
					className="fs-1075"
					style={{
						margin: '1.5rem auto 2.5rem auto',
						color: '#2379e8',
						fontWeight: 600,
						width: '100%',
						textAlign: 'center',
					}}
				>
					No Data
				</div>
			)}

			{loading.barchart && (
				<div
					style={{
						margin: '1.5rem 0 2.5rem 0',
						height: '2.5rem',
					}}
				>
					<Loading />
				</div>
			)}

			<h4
				className="fs-0775"
				style={{
					color: '#9799a1',
					margin: '0 0 0 0',
					fontWeight: 400,
				}}
			>
				&nbsp;&nbsp;&nbsp;&nbsp;*Stock market data as of{' '}
				{data?.barchart?.lastUpdated || 'XX:XX ET'}.
			</h4>

			<div
				style={{
					width: '100%',
					margin: '2rem 0 0.5rem 0',
					borderCollapse: 'separate',
				}}
			>
				<table
					style={{
						width: '100%',
						tableLayout: 'fixed',
					}}
				>
					<tr style={{ width: '100%' }}>
						<td align="left">
							<img
								src="https://i.ibb.co/rZkMw3P/image.png"
								style={{ width: '94%' }}
								alt=""
							/>
						</td>
						<td align="center">
							<img
								src="https://i.ibb.co/QfnqN0z/image.png"
								style={{
									width: '94%',
								}}
								alt=""
							/>
						</td>
						<td align="right">
							<img
								src="https://i.ibb.co/6tMLBHv/image.png"
								style={{ width: '94%' }}
								alt=""
							/>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
}

export default InTodatsByzz;
