import React from 'react';

function Error() {
	return (
		<div
			className="render-ignore"
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				placeItems: 'center',
			}}
		>
			<div
				style={{
					width: '1.5rem',
					height: '1.5rem',
					backgroundColor: 'rgba(207, 14, 75, 0.2)',
					borderRadius: '9999px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '0.75rem',
						height: '0.75rem',
						backgroundColor: '#cf0e4b',
						borderRadius: '9999px',
					}}
				></div>
			</div>
			<h4
				className="fs-075"
				style={{
					color: '#cf0e4b',
					marginLeft: '0.5rem',
					fontWeight: 500,
				}}
			>
				The service is currently unavailable.
			</h4>
		</div>
	);
}

export default Error;
