import React from 'react';

function convertToEllipsis(e: any) {
	const initialValue = e.target.innerHTML;

	if (initialValue.includes('[...]')) {
		e.preventDefault();
		e.target.innerHTML = initialValue
			.split('[...]')
			.join('<span class="elipsis">[…]</span>');
	}
}

function truncateTitle(title: string) {
	if (!title) return;

	// if (title.length > 30) {
	// 	return [
	// 		title.split(' ').reduce((acc, curr) => {
	// 			if (acc.length + curr.length > 35) {
	// 				return acc;
	// 			}

	// 			return `${acc} ${curr}`;
	// 		}),
	// 		<span
	// 			className="elipsis"
	// 			style={{
	// 				color: '#cf0e4b',
	// 			}}
	// 			key={0}
	// 		>
	// 			&nbsp;[...]
	// 		</span>,
	// 	];
	// }

	return [title, null];
}

function InTodatsByzz({ data }: { data: any }) {
	return (
		<div
			style={{
				width: 'full',
				backgroundColor: 'white',
				borderRadius: '0.5rem',
				marginTop: '1.25rem',
			}}
		>
			<img
				style={{
					height: '26rem',
					width: '100%',
					objectFit: 'cover',
					objectPosition: 'top',
					borderTopLeftRadius: '0.5rem',
					borderTopRightRadius: '0.5rem',
					display: 'block',
				}}
				src={
					data?.investorbuzz?.image
						? `https://investorbuzz.com${data?.investorbuzz?.image}`
						: 'https://i.ibb.co/1r9bjyq/image.png'
				}
				alt=""
			/>
			<div
				style={{
					borderLeft: '1px solid #e7f1ff',
					borderRight: '1px solid #e7f1ff',
				}}
			>
				<div
					className="fs-1875"
					style={{
						color: '#000000',
						fontWeight: 700,
						paddingTop: '1.75rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						outline: 'none',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					contentEditable={true}
				>
					{truncateTitle(data?.investorbuzz?.title)}
				</div>
				<div
					contentEditable={true}
					className="fs-125"
					style={{
						outline: 'none',
						color: '#2379e8',
						fontWeight: 600,
						marginTop: '1.75rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
				>
					Write a amazing subject line
				</div>
				<div
					className="fs-1125"
					style={{
						outline: 'none',
						color: '#000000',
						fontWeight: 400,
						marginTop: '0.5rem',
						textIndent: '1rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
					contentEditable={true}
					onBlur={convertToEllipsis}
				>
					If you see this, that means{' '}
					<b>we couldn&apos;t automatically fetch</b> this paragraph
					from the website. So you will sadly have to write it here,
					but don&apos;t despair; you can copy-paste it from the
					article and remember to remove the formatting.
				</div>
				<div
					className="fs-1125"
					style={{
						outline: 'none',
						color: '#000000',
						fontWeight: 400,
						marginTop: '0.5rem',
						textIndent: '1rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
					contentEditable={true}
					onBlur={convertToEllipsis}
				>
					I got you covered with another paragraph, just in case you
					need it. Don&apos;t forget to remove to <b>emphasize</b>{' '}
					important sections.
				</div>
				<div
					className="fs-125"
					style={{
						outline: 'none',
						color: '#2379e8',
						fontWeight: 600,
						marginTop: '1.75rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
					}}
					contentEditable={true}
				>
					Another subject line and you are done
				</div>
				<div
					className="fs-1125"
					style={{
						outline: 'none',
						color: '#000000',
						fontWeight: 400,
						marginTop: '0.5rem',
						padding: '0 1rem 2.25rem 1rem',
					}}
				>
					<div
						className="fs-1125"
						style={{
							outline: 'none',
							color: '#000000',
							fontWeight: 400,
							marginTop: '0.5rem',
							textIndent: '1rem',
						}}
						contentEditable={true}
						onBlur={convertToEllipsis}
					>
						Again, if you see this, that means{' '}
						<b>we couldn&apos;t automatically fetch</b> this
						paragraph from the website. So you will sadly have to
						write it here, but don&apos;t despair; you can
						copy-paste it from the article and remember to remove
						the formatting.
					</div>
				</div>
			</div>

			<table
				style={{
					width: '100%',
					backgroundColor: '#e7f1ff',
					borderBottomLeftRadius: '0.5rem',
					borderBottomRightRadius: '0.5rem',
					paddingTop: '0.2rem',
					paddingBottom: '0.2rem',
				}}
			>
				<tr>
					<td
						style={{
							width: '1.90rem',
							paddingLeft: '1rem',
							paddingRight: '0.2rem',
							verticalAlign: 'middle',
						}}
					>
						<a
							style={{
								textDecoration: 'none',
							}}
							href={
								data?.investorbuzz?.url ||
								'https://investorbuzz.com'
							}
						>
							<img
								style={{
									width: '90%',
									marginRight: '0.75rem',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/Lps2LN2/image.png"
								alt=""
							/>
						</a>
					</td>
					<td>
						<a
							style={{
								textDecoration: 'none',
							}}
							href={
								data?.investorbuzz?.url ||
								'https://investorbuzz.com'
							}
						>
							<div
								className="fs-1125"
								style={{
									color: '#2379e8',
									fontWeight: 400,
								}}
							>
								Keep reading
							</div>
						</a>
					</td>

					<td
						align="right"
						style={{
							width: '1.5rem',
						}}
					>
						<a
							href={
								'https://www.facebook.com/sharer/sharer.php?u=' +
								data?.investorbuzz?.url
							}
							style={{
								width: '100%',
								marginRight: '1.35rem',
							}}
						>
							<img
								style={{
									width: '1.5rem',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/Cv491Nr/image.png"
							/>
						</a>
					</td>

					<td
						align="right"
						style={{
							width: '1.5rem',
						}}
					>
						<a
							href={`http://twitter.com/share?text=${data?.investorbuzz?.title}&url=${data?.investorbuzz?.url}`}
							style={{
								width: '100%',
								marginRight: '1.35rem',
							}}
						>
							<img
								style={{
									width: '1.5rem',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/ynLcfgG/image.png"
							/>
						</a>
					</td>

					<td
						align="right"
						style={{
							width: '1.5rem',
							paddingRight: '1rem',
						}}
					>
						<a
							href={`mailto:?subject=${data?.investorbuzz?.title}&body=${data?.investorbuzz?.url}`}
							style={{
								width: '100%',
							}}
						>
							<img
								style={{
									width: '100%',
									verticalAlign: 'middle',
								}}
								src="https://i.ibb.co/5xsv1W7/image.png"
							/>
						</a>
					</td>
				</tr>
			</table>
		</div>
	);
}

export default InTodatsByzz;
